.brandregister-inputcontainer{ 
    display: flex;
    justify-content: space-between;

}


.midbox3 {
    width: 90%;
    height: 47px;
    margin: 11.5px 0 16.3px;
    border-radius: 4px;
    box-shadow: 1px 1px 4px 0 rgb(0 0 0 / 10%);
    background-color: #ffffff;
}
.midbox{
    width: 88%;
    margin: 24px 0 21.3px;
    margin-left: 6.3%;
    border-radius: 10px;
    box-shadow: 1px 1px 4px 0 rgb(0 0 0 / 10%);
    background-color: #ffffff;
}
.midbox1 {
    width: 88%;
    margin: 24px 0 21.3px;
    margin-left: 6.3%;
    border-radius: 10px;
    box-shadow: 1px 1px 4px 0 rgb(0 0 0 / 10%);
    background-color: #ffffff;
}
.midbox12 {
    width: 88%;
    margin: 24px 0 21.3px;
    margin-left: 6.3%;
    border-radius: 10px;
    box-shadow: 1px 1px 4px 0 rgb(0 0 0 / 10%);
    background-color: #fafafa;
}
.midbox12a {
    width: 88%;
    margin: 24px 0 21.3px;
    margin-left: 6.3%;
    border-radius: 10px;
    box-shadow: 1px 1px 4px 0 rgb(0 0 0 / 10%);
}
.smallChoiceBoxBlue{
    color: #fff;
    font-size: 14px;
    padding : 1.7px 14.4px 3px 13.6px;
    border-radius: 5px;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #4a64f5;
    display : inline-block;
    float: right;
}
.disabled-link {
    pointer-events: none;
}
.com{
    object-fit: cover;
    width: 95%;
    padding: 0px;
    display: block;
    margin: 0px auto;
    margin-top: 30px;
    padding-top: 10px;
    padding-bottom: 12px;
    margin-bottom: 50px;
}
.smallChoiceBoxWhite{
    color: #4a64f5;
    font-size: 14px;
    padding : 1.7px 14.4px 3px 13.6px;
    border-radius: 5px;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    display : inline-block;
    float: right;
}
#next_button {
    font-family : Regular1;
    font-size: 17px;
    border: none;
    height: 60px;
    width: 100%;
    max-width: 520px;
}
input:focus{
    outline: none;
}
.switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 25px;
    vertical-align:middle;
}
.switch1 {
    position: relative;
    display: inline-block;
    width: 65px;
    height: 30px;
    vertical-align:middle;
    margin-top: -3px;
}
.switch2 {
    position: relative;
    display: inline-block;
    width: 52px;
    height: 25px;
    vertical-align:middle;
    margin-top: -2px;
}
/* Hide default HTML checkbox */
.switch input {display:none;}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #b7b7b7;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 19px;
    width: 19px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider1 {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #b7b7b7;
    -webkit-transition: .4s;
    transition: .4s;
}
.slider2 {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #b7b7b7;
    -webkit-transition: .4s;
    transition: .4s;
}
.slider1:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
.slider2:before {
    position: absolute;
    content: "";
    height: 19px;
    width: 19px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
input::placeholder {
    color: #898989;
}
input:checked + .slider {
    background-color: #4a64f5;
}

input:focus + .slider {
    box-shadow: 0 0 1px #4a64f5;
}
input:checked + .slider1 {
    background-color: #4a64f5;
}
input:checked + .slider2 {
    background-color: #4a64f5;
}
input:focus + .slider1 {
    box-shadow: 0 0 1px #4a64f5;
}

input:focus + .slider2 {
    box-shadow: 0 0 1px #4a64f5;
}
input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(18px);
}
input:checked + .slider1:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(33px);
}
input:checked + .slider2:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(25px);
}
input:checked {
    padding-left: 44px;
    padding-top: 2px;
  }
/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}
.slider1.round {
    border-radius: 34px;
}
.slider2.round {
    border-radius: 34px;
}
.slider.round:before {
    border-radius: 50%;
}
.slider1.round:before {
    border-radius: 50%;
}
.slider2.round:before {
    border-radius: 50%;
}
.midbox23 {
    width: 89%;
    height: 47px;
    margin: 11.5px 0 21.3px;
    border-radius: 4px;
    box-shadow: 1px 1px 4px 0 rgb(0 0 0 / 10%);
    background-color: #ffffff;
}
.midbox2 {
    width: 88%;
    margin: 24px 0 21.3px;
    margin-left: 6.3%;
    border-radius: 10px;
    box-shadow: 1px 1px 4px 0 rgb(0 0 0 / 10%);
    background-color: #edeffe;
}
/*@media(max-width : 490px){*/
/*    #discountAmount{*/
/*        margin-right : 5%;*/
/*    }*/
/*}*/
/*@media(min-width : 491px){*/
/*    #discountAmount{*/
/*        margin-right : 10%;*/
/*    }*/
/*}*/


.grad {
    width: 100%;
    background: linear-gradient(to bottom, white 0%, #4a64f5 50%, white 100%);
  }
#next_button {
    font-family : Regular1;
    font-size: 17px;
    border: none;
    height: 60px;
    width: 100%;
    max-width: 520px;
}
#next_button6 {
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 60px;
    background-color: #4a64f5;
    background-repeat: no-repeat;
    text-align: center;
    background-size: 100% 100%;
    border: none;
    color: #ffffff;
    font-size: 17px;
}
.changeImg{
    transform: rotate(180deg);
    padding-top: 30px;
}
.res {
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    background-color: #ffffff;
    text-align: center;
    width: 100%;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 14%);
    padding-top: 10px;
    position: fixed;
    bottom: 59px;
}
* {
    margin: 0;
    padding: 0;
}


/* .brandregister-input{
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: left;
    color: #b7b7b7;

  width: 335px;
  height: 29px;
  border-style: none;
  border-bottom: solid 2px #b7b7b7;


}
.brandregister-input1{
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: left;

  width: 335px;
  height: 29px;
  border-style: none;


} */
.slider.round:before {
    border-radius: 50%;
}
.slider:before {
    position: absolute;
    content: "";
    height: 19px;
    width: 19px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #b7b7b7;
    -webkit-transition: .4s;
    transition: .4s;
}
.slider.round {
    border-radius: 34px;
}
.l2 {
    color: #898989;
    display: inline;
    float: left;
    opacity: 0.7;
}

.switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 25px;
    vertical-align: middle;
}

.switch input {
    display: none;
}
.informchecktext {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    margin-bottom: 5px;
    width: 61%;
    height: 40px;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: left;
    color: #b7b7b7;
}
.informchecktext14 {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    margin-bottom: 5px;
    width: 67%;
    height: 40px;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: left;
    color: #b7b7b7;
}
.informchecktext2 {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    margin-bottom: 5px;
    width: 76.5%;
    height: 40px;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: left;
    color: #b7b7b7;
}
.headLine2 {
    margin-top: 30px;
    margin-left: 20px;
    font-size: 30px;
    line-height: 1.27;
    text-align: left;
    color: #000000;
}
.brandregister-input:focus{ 
    caret-color: #4a64f5;
    outline: none;
    border-style: none;
    border-bottom: solid 2px #4a64f5;
}

.brandregister-inputbutton{
    width: 16px;
    height: 16px;
    background-color: #efefef;
    padding: 4.5px 4.5px 4.5px 4.5px;
    margin: 10px 20px 12.1px 0;
    border-style: none;
    font-size: 3px;
    text-align: center;
} 


.brandlogoregister-plusbox{
    display: block;
    width: 335px;
    height: 253px;
    margin: 43px 35px 203px;
    object-fit: contain;

}

.dropdowncomponent-container{ 
    margin-top: 44px;
}




