.fade-banner-container {
    overflow: hidden;
    position: absolute;
    margin-top: -169px;
    width: 251px;
    margin-left: 81px;
    height: 151px;
  }
  
  .fade-banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 1s ease;
  }
  
  .fade-banner.active {
    opacity: 1;
  }
  