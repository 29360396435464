.fade-banner-container2 {
    overflow: hidden;
    position: absolute;
    margin-top: -85px;
    width: 152px;
    height: 76px;
    margin-left: -15px;
  }
  
  .fade-banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 1s ease;
  }
  
  .fade-banner.active {
    opacity: 1;
  }
  