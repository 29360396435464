/* .textcont { 
            border:none;
            outline: none;
            padding: 0px;
               width: 80%;
               height: 40px;
               font-size: 20px;
               line-height: 1.1;
               letter-spacing: normal;
               text-align: left;
               color: #b7b7b7;
               margin-bottom: 5px;

} */

.informname{ 
    font-size: 12px;
}