@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
@import url(http://fonts.googleapis.com/earlyaccess/notosanskr.css);

@font-face {
    font-family : "Bold";
    src : url("./font/NotoSansCJKkr-Bold.otf") format('truetype');
}
.Bold{
    font-family: Bold1
}
.Bold2{
    font-family: Bold
}
@font-face {
    font-family : "Regular";
    src : url("./font/NotoSansCJKkr-Regular.otf") format('truetype');
    
}
.Regular{
    font-family: Regular1
}


@font-face {
    font-family : "Light";
    src : url("./font/NotoSansCJKkr-Light.otf") format('truetype');
}
.Light{
    font-family: Light1
}
@font-face {
    font-family : "Medium";
    src : url("./font/NotoSansCJKkr-Medium.otf") format('truetype');
}
.Medium{
    font-family: Medium1
}
@font-face {
    font-family : "Bold1";
    src : url(./font/Pretendard-SemiBold.subset.woff2) format('truetype');
}
.Bold1{
    font-family: Bold1
}
@font-face {
    font-family : "Light1";
    src : url(./font/Pretendard-Light.subset.woff2) format('truetype');
}
.Light1{
    font-family: Light1
}
@font-face {
    font-family : "Medium1";
    src : url(./font/Pretendard-Medium.subset.woff2) format('truetype');
}
.Medium1{
    font-family: Medium1
}
@font-face {
    font-family : "Regular1";
    src : url(./font/Pretendard-Regular.subset.woff2) format('truetype');
}
.Regular1{
    font-family: Regular1
}
.brand-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1200px;
    margin-left: calc(50% - 600px);
    margin-top: 25px;
    height: 440px;
  }
  
  @media (max-width: 1200px) {
    .brand-container {
      flex-direction: column;
      align-items: center;
    }
  
    .brand-container > div {
      width: 90%; /* 너비를 조정할 수 있습니다 */
    }
  }
#start_btn {
    width: 60%;
    font-family: Regular1;
    background-color: #4a64f5;
    background-repeat: no-repeat;
    text-align: center;
    background-size: 100% 100%;
    border: none;
    color: #ffffff;
    line-height: 3;
    font-size: 17px;
    border-radius: 10px;
    margin-left: 14.65%;
}
#start_btn4 {
    width: 300px;
    font-family: Regular1;
    background-color: #4a64f5;
    background-repeat: no-repeat;
    text-align: center;
    background-size: 100% 100%;
    border: none;
    color: #ffffff;
    line-height: 3;
    font-size: 17px;
    border-radius: 10px;
    margin-left: calc(50% - 150px);

}
.btn:focus,
.btn:active,
.btn:active:focus,
.custom-select:focus,
.form-control:focus {
    box-shadow: none !important;
    outline: none;
}
.categorybox{
    cursor : pointer;
    padding-top: 16px;
    padding-bottom: 12px;
    border-radius: 5px;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #e0e0e0;
    background-color: #fff;
    margin-right : 20px;
}
.nonselect{
    cursor : pointer;
    background-color: #707070;
}
.select{
    cursor : pointer;
    background-color: #4a64f5;
}
.categorybox11{
    cursor : pointer;
    padding-top: 25px;
    padding-bottom: 25px;
    border-radius: 5px;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #e0e0e0;
    background-color: #fff;
    margin-right : 20px;
}
.categorybox111{
    cursor : pointer;
    border-radius: 5px;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #e0e0e0;
    background-color: #fff;
}
.categorybox222{
    cursor : pointer;
    border-radius: 5px;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #4f63ec;
    background-color: #fff;
    color : #4f63ec;
    background-color: #f5f6ff;
}
.categorybox22{
    cursor : pointer;
    padding-top: 25px;
    padding-bottom: 25px;
    border-radius: 5px;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #4f63ec;
    background-color: #fff;
    color : #4f63ec;
    margin-right : 20px;
    background-color: #f5f6ff;
}
.categorybox2{
    cursor : pointer;
    padding-top: 16px;
    padding-bottom: 12px;
    border-radius: 5px;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #4f63ec;
    background-color: #f5f6ff;
    color : #4f63ec;
    margin-right : 20px;
}
.categorybox3{
    cursor : pointer;
    padding-top: 16px;
    padding-bottom: 12px;
    border-radius: 5px;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #4f63ec;
    background-color: #fff;
    color : #4f63ec;
    margin-right : 15px;
}
.loadingdots {
    height: 12px;
    width: 12px;
    border-radius:50%;
    transform: scale(0);
    background-color:red;
    animation: scaling 2s ease-in-out infinite;
    display: inline-block;
    margin:.3rem;
}

.loadingdots:nth-child(1){
    animation-delay:0s;
}
.loadingdots:nth-child(2){
    animation-delay:0.5s;
}
.loadingdots:nth-child(3){
    animation-delay: 1s;
}
.dot-pulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #4a64f5;
    color: #4a64f5;
    box-shadow: 9999px 0 0 -5px #4a64f5;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: .25s;
}

.dot-pulse::before, .dot-pulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #4a64f5;
    color: #4a64f5;
}

.dot-pulse::before {
    box-shadow: 9984px 0 0 -5px #4a64f5;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
}

.dot-pulse::after {
    box-shadow: 10014px 0 0 -5px #4a64f5;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: .5s;
}

@keyframes dotPulseBefore {
    0% {
        box-shadow: 9984px 0 0 -5px #4a64f5;
    }
    30% {
        box-shadow: 9984px 0 0 2px #4a64f5;
    }
    60%,
    100% {
        box-shadow: 9984px 0 0 -5px #4a64f5;
    }
}

@keyframes dotPulse {
    0% {
        box-shadow: 9999px 0 0 -5px #4a64f5;
    }
    30% {
        box-shadow: 9999px 0 0 2px #4a64f5;
    }
    60%,
    100% {
        box-shadow: 9999px 0 0 -5px #4a64f5;
    }
}

@keyframes dotPulseAfter {
    0% {
        box-shadow: 10014px 0 0 -5px #4a64f5;
    }
    30% {
        box-shadow: 10014px 0 0 2px #4a64f5;
    }
    60%,
    100% {
        box-shadow: 10014px 0 0 -5px #4a64f5;
    }
}
.noto{
    font-family: "Noto Sans KR", sans-serif;
}
#text {
    margin-bottom: 5px;
    font-family: "Noto Sans KR", sans-serif;
    width: 80%;
    height: 40px;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: left;
    color: #b7b7b7;
}
#text1 {
    margin-bottom: 5px;
    font-family: "Noto Sans KR", sans-serif;
    width: 80%;
    font-size: 20px;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: left;
    color: #b7b7b7;
}
#introBox{
    height: 750px;
    margin-top: 52px;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.16);
    background-color: #fafafa;
}
#introBox1{
    height: 700px;
    margin-top: 22px;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.16);
    background-color: #f8f9ff;
}
#innerBoxOn{
    width: 500px;
    height: 150px;
    background-color: #fafafa;
    line-height: 150px;
}
#introFooter{
    position: fixed;
    bottom: 0;
    width: 100%;
}
#innerBox{
    width: 500px;
    height: 150px;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    line-height: 150px;
}
.sucin2s {
    font-family: "Noto Sans KR", sans-serif;
    color: #000000;
    font-size: 14px;
    float: right;
    font-weight: 400;

}
.sucin2 {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 14px;
    color: #898989;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
}
.sucin211 {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 14px;
    color: #000;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
}
#a04 {
    margin: 4% 20px 0 0;
    float: right;
    font-size: 16px;
    color: #b7b7b7;
}
#a01 {

    float: right;
    margin: 4% 4px 0 0;
    font-size: 16px;
    font-weight: normal;
    text-align: left;
    color: #4a64f5;
}
.btn:focus {
    box-shadow: none !important;
    outline: none;
}
.btn:active {
    box-shadow: none !important;
    outline: none;
}
.btn:active:focus {
    box-shadow: none !important;
    outline: none;
}
.circle{
    width:113px;
    height:113px;
    border-radius: 70px;
    border: solid 1px #cccccc;
}
#midbox{
    width: 80%;
    height: 275px;
    margin: 24px 0 21.3px;
    margin-left : 10%;
    border-radius: 10px;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
}
#start_btn2 {
    width: 230px;
    font-family: Regular;
    background-color: #4a64f5;
    background-repeat: no-repeat;
    text-align: center;
    background-size: 100% 100%;
    border: none;
    color: #ffffff;
    font-size: 17px;
    border-radius: 3px;
    margin-left : -115px;
}
#fontblue{
    color: #4a64f5;
}
#fontgray{
    color: #898989;
}
.asd {
    font-size: 16px;
    color: rgb(74, 100, 245);
    text-align: center;
    display: inline-block;
    transform: translateY(-5px);
    margin-top: 15px;
}
.asdaa{

    font-size: 12px;
    color: rgb(183, 183, 183);
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 140px;

}